<template>
  <v-row class="scripts">
    <v-col cols="3" v-if="loading">
      <div class="scripts__headline">
        <v-skeleton-loader
            type="heading"
            width="100%"
            height="100%"
            class="ml-4"
        />
      </div>
      <div class="scripts__items">
        <v-skeleton-loader
            type="list-item"
            width="100%"
            height="100%"
            v-for="i in 7"
            :key="i"
        />
      </div>
    </v-col>
    <v-col cols="3" v-if="!loading">
      <div class="scripts__headline">
        <h3>Группы <br> фраз</h3>
        <v-btn text icon @click="createNewScriptGroup" v-if="hasRight('scripts.manage')">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
      <v-text-field
          label="Поиск"
          class="evi-text-field mt-5 mr-3"
          color="#44D370"
          v-model="search"
          clearable
      >
        <template v-slot:append>
          <img class="mt-1" width="15" :src="require('@/assets/img/analytics/search.svg')" alt="">
        </template>
      </v-text-field>
      <div class="scripts__items">
        <div class="scripts__item" @click="selectScriptGroup(item)" v-for="item in filteredScriptGroups" :key="item.id">
          <img :src="require('@/assets/img/menu/scripts.svg')" alt="" class="mr-3">
          <span class="title-name">
              {{ item.name }}
              <span class="title-line" :class="{'active' : item.id === selectedScriptGroup.id}"/>
          </span>
        </div>
      </div>
    </v-col>
    <v-divider vertical></v-divider>
    <v-col class="ml-5" v-if="loading">
      <div class="scripts__headline mb-2">
        <v-skeleton-loader
            type="heading"
            width="100%"
            height="100%"
        />
      </div>
      <div class="roles__fields">
        <v-skeleton-loader
            type="heading"
            width="100%"
            height="100%"
            class="mb-7"
        />
        <v-skeleton-loader
            type="heading"
            width="100%"
            height="100%"
            class="mb-7"
        />
        <v-skeleton-loader
            type="card, actions"
            width="350"
            height="100%"
        />
      </div>
    </v-col>
    <v-col class="ml-5" v-if="!loading">
      <div class="excel-buttons mb-11 text-right">
        <v-btn class="evi-button-green export mr-2" v-if="hasRight('scripts.import')" @click="importModal = true">
          <img class="mr-2" width="23" :src="require('@/assets/img/common/import.svg')" alt="">
          Импорт
        </v-btn>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="evi-button-green export mr-2" v-if="hasRight('scripts.export')" v-on="on" :bind="attrs">
              <img class="mr-2" width="23" :src="require('@/assets/img/common/export.svg')" alt="">
              Экспорт
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item-group>
              <v-list-item
                  v-for="(format, index) in importFormats"
                  :key="index"
                  class="pointer"
                  @click="download(format)"
              >
                <v-list-item-icon class="evi-sidebar__icon">
                  <img width="16" :src="require(`@/assets/img/analytics/excel.svg`)" alt=""/>
                </v-list-item-icon>
                <v-list-item-title class="v-list-item__title">Экспортировать .{{ format }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </div>
      <div class="scripts__headline mb-2" v-if="scriptGroups.length">
        <h4 class="mt-1">ГРУППА ФРАЗ — {{ selectedScriptGroup.name }}</h4>
        <v-btn class="evi-delete-button" v-if="isTabScriptGroup && hasRight('scripts.manage')"
               @click="deleteScriptGroupModal = true" color="#ffffff" height="59">
          Удалить группу
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>
        <v-btn v-else-if="!isTabScriptGroup && hasRight('scripts.manage')"
               class="evi-button-green evi-button-green--fill" @click="createNewScript">
          Создать фразу
        </v-btn>
      </div>
      <v-tabs
          v-model="tab"
          align-with-title
          class="scripts__tabs"
          v-if="scriptGroups.length"
      >
        <v-tabs-slider color="#44D370"></v-tabs-slider>
        <v-tab
            v-for="item in items"
            :key="item"
        >
          {{ item }}
        </v-tab>
      </v-tabs>
      <div class="scripts__fields" v-if="scriptGroups.length">
        <ScriptGroupFields v-if="isTabScriptGroup" :selectedScriptGroup="selectedScriptGroup" @reset="reset"
                           @saveScriptGroup="saveScriptGroup"/>
        <ScriptsFields
            :scripts="selectedScriptGroup.scripts"
            :scriptGroups="scriptGroups"
            :currentGroupId="selectedScriptGroup.id"
            :currentGroupName="selectedScriptGroup.name"
            v-else
        />
      </div>
      <v-dialog
          v-model="deleteScriptGroupModal"
          max-width="600"
      >
        <v-card>
          <v-card-title class="text-h5">
            Удаление группы фраз
          </v-card-title>
          <v-card-text>Вы действительно хотите удалить группу быстрых фраз <span
              class="font-weight-bold">"{{ selectedScriptGroup.name }}"</span>?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                text
                @click="deleteScriptGroupModal = false"
            >
              Отмена
            </v-btn>
            <v-btn
                color="red"
                text
                @click="deleteScriptGroupItem"
            >
              Удалить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="importModal" width="700">
        <ImportModal :data="importData" @uploadData="upload" @close="importModal = false"></ImportModal>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import {mapActions, mapState} from "vuex";
import ScriptGroupFields from "../../components/scripts/ScriptGroupFields";
import ScriptsFields from "../../components/scripts/ScriptsFields";
import {hasRight} from '@/utils/access/hasRight';
import ImportModal from "@/components/сommon/EsTable/ImportModal";
import exportData from "@/utils/files/requestExport";

export default {
  name: "Scripts",
  components: {ImportModal, ScriptsFields, ScriptGroupFields},
  data: () => ({
    tab: 0,
    search: "",
    items: [
      'Группа фраз', 'Фразы',
    ],
    importData: {
      title: 'Импорт быстрых фраз',
      template: 'scripts'
    },
    selectedScriptGroup: {},
    deleteScriptGroupModal: false,
    importModal: false,
    importFormats: ['xlsx', 'csv'],
  }),
  computed: {
    ...mapState("scripts", ["scriptGroups", "groupScripts"]),
    ...mapState("common", ["loading", "tableMeta"]),
    isTabScriptGroup() {
      return this.tab === 0;
    },
    filteredScriptGroups() {
      if (!this.search) {
        return this.scriptGroups;
      }
      return this.scriptGroups.filter(i => {
        return i.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
      })
    },
  },
  methods: {
    ...mapActions({
      getScriptGroups: 'scripts/getScriptGroups',
      createScriptGroup: 'scripts/createScriptGroup',
      deleteScriptGroup: 'scripts/deleteScriptGroup',
      getSelectedScriptGroup: 'scripts/getSelectedScriptGroup',
      saveSelectedScriptGroup: 'scripts/saveSelectedScriptGroup',
      createScript: 'scripts/createScript',
      uploadData: 'scripts/uploadData',
      downloadData: 'scripts/downloadData',
    }),
    hasRight: hasRight,
    createNewScriptGroup() {
      this.createScriptGroup().then(() => {
        this.scriptsInit();
      });
    },
    upload(payload) {
      this.uploadData(payload).then(() => {
        this.getScriptGroups();
      });
    },
    deleteScriptGroupItem() {
      this.deleteScriptGroup(this.selectedScriptGroup).then(() => {
        this.selectedScriptGroup = {};
        this.scriptsInit();
      });
      this.deleteScriptGroupModal = false;
    },
    selectScriptGroup(item) {
      if (item) {
        this.selectedScriptGroup = item;
        this.getSelectedScriptGroup(item.id);
      }
    },
    scriptsInit() {
      this.getScriptGroups().then(() => {
        if (!this.selectedScriptGroup.name) {
          this.selectScriptGroup(this.scriptGroups[0]);
        } else {
          let item = this.scriptGroups.find(i => i.id === this.selectedScriptGroup.id);
          this.selectScriptGroup(item);
        }
      });
    },
    reset() {
      this.selectScriptGroup(this.selectedScriptGroup);
    },
    saveScriptGroup(item) {
      this.saveSelectedScriptGroup(item);
    },
    createNewScript() {
      let payload = {
        name: `Новая фраза${this.tableMeta.total + 1}`,
        text: 'Привет, [Клиент.Имя]!',
        script_group_id: this.selectedScriptGroup.id,
        script_group: {
          name: this.selectedScriptGroup.name
        }
      };
      this.createScript(payload);
    },
    download(payload) {
      exportData(this.$store.dispatch, 'scripts/downloadData', {export: payload}, `Быстрые фразы`);
    },
  },
  mounted() {
    this.scriptsInit();
  }
}
</script>

<style lang="scss" scoped>
.scripts {
  padding: 60px 25px;
  background: #FFFFFF;
  //box-shadow: 10px -2px 54px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  min-height: 743px;

  &__headline {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-weight: bold;
      font-size: 20px;
      color: #2C3136;
      text-transform: uppercase;
    }

    i {
      color: $evi-headline-color !important;
    }

    h4 {
      font-weight: 600;
      font-size: 14px;
      color: $evi-headline-color !important;
      text-transform: uppercase;
      margin-bottom: 15px;
    }
  }

  &__items {
    margin-top: 49px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
  }

  &__item {
    color: $evi-headline-color;
    display: flex;
    cursor: pointer;
    margin-bottom: 15px;

    .title-name {
      display: inline;
      font-weight: 600;
      font-size: 14px;
      position: relative;
    }

    .title-line {
      display: block;
      height: 1px;
      width: 100%;
      position: absolute;
      background: $evi-headline-color;
      opacity: 0;
      transition: 0.2s;
      left: 0;
      bottom: -4px;

      &.active {
        opacity: 1;
      }
    }

    &:hover {
      .title-line {
        opacity: 1;
      }
    }
  }

  &__tabs {
    .v-tab {
      color: $evi-headline-color;
      font-weight: 600;
      font-size: 14px;
      text-transform: none;
      margin-left: 0 !important;
      letter-spacing: 0 !important;
      padding: 0;
      margin-right: 20px;
      min-width: auto;
    }
  }

  .evi-delete-button {
    width: 226px;
  }

  .evi-button-green {
    height: 47px;
    width: 195px !important;
  }

  .excel-buttons {
    button {
      height: 36px;
      min-width: 64px;
      padding: 0 16px;
    }
  }
}
</style>
