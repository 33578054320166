<template>
  <div>
    <div class="es-table__header">
      <div class="es-table__filtration">
        <div id="pagination-component" class="es-table__showby d-flex align-start">
          <v-text-field
              label="Поиск"
              class="evi-text-field scripts-search mr-5"
              color="#44D370"
              v-model="search"
              clearable
          >
            <template v-slot:append>
              <img class="mt-1" width="15" :src="require('@/assets/img/analytics/search.svg')" alt="">
            </template>
          </v-text-field>
        </div>
        <div class="d-flex">
          <div class="evi-pagination d-flex justify-space-between">
            <v-pagination
                v-model="page"
                :length="pagesLength || 0"
                :total-visible="5"
                color="#725DFF"
                class="evi-pagination__pages"
                @input="init"
            />
          </div>
        </div>
      </div>
      <span class="show-scripts">Показано с {{ firstShowBy }} по {{ maxShowBy }} из {{ tableMeta.total }} записей</span>
    </div>
    <div class="mt-12" v-if="localLoading">
      <v-skeleton-loader
          type="card"
          width="100%"
          height="100%"
          v-for="i in 5"
          :key="i"
          class="mb-5"
      />
    </div>
    <div class="script-fields" v-else-if="localScripts.length">

      <div class="mt-5">
        <div v-for="(item, index) in localScripts" :key="item.id" class="script-fields__item">
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <div class="script-fields__menu" v-on="on" :bind="attrs" v-if="hasRight('scripts.manage')">
                <v-icon>mdi-dots-horizontal</v-icon>
              </div>
            </template>
            <v-list class="script-fields-menu">
              <v-list-item-group>
                <v-list-item>
                  <v-list-item-icon class="mr-2">
                    <v-icon color="#008A80">mdi-arrow-right</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    <v-autocomplete
                        class="evi-autocomplete"
                        color="#44D370"
                        v-model="item.script_group_id"
                        :items="scriptGroups"
                        item-text="name"
                        item-value="id"
                        hide-details
                        @change="changeGroup(item)"
                        clearable
                        item-color="green"
                    >
                      <template v-slot:label>
                        Перенести в группу быстрых фраз
                      </template>
                      <template v-slot:append>
                        <span class="evi-select__arrow">
                            <v-icon>mdi-chevron-down</v-icon>
                        </span>
                      </template>
                    </v-autocomplete>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="deleteScriptModalOpen(item)">
                  <v-list-item-icon class="mr-2">
                    <v-icon color="#ED4245">mdi-trash-can-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Удалить</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
          <v-text-field
              class="evi-text-field mb-6"
              color="#44D370"
              v-model="item.name"
              @change="editScript(item)"
              :disabled="!hasRight('scripts.manage')"
          >
            <template v-slot:label>
              Название быстрой фразы
            </template>
          </v-text-field>
          <div class="attachments mb-6">
            <div class="attachments__headline">Вложения</div>
            <div class="d-flex">
              <v-file-input
                  chips
                  multiple
                  class="evi-text-field file-input mb-5"
                  color="#44D370"
                  @change="editScriptData(item)"
                  v-model="files"
                  :disabled="!hasRight('scripts.manage')"
                  hide-input
                  hide-details
              />
              <div class="attachments__items mt-4">
                <div v-for="a in item.attachments" :key="a.id" class="attachments__item">
                  <v-icon @click="downloadFile(a.url, a.name)" size="20" class="mr-2">mdi-download</v-icon>
                  <span @click="downloadFile(a.url, a.name)">{{ a.name }}</span>
                  <v-icon v-if="hasRight('scripts.manage')" @click="removeFile(item.id, a.id)" class="close" size="20">
                    mdi-close
                  </v-icon>
                </div>
              </div>
            </div>
          </div>
          <v-textarea
              no-resize
              class="evi-textarea"
              color="#44D370"
              v-model="item.text"
              @change="editScript(item)"
              :readonly="!hasRight('scripts.manage')"
              :ref="`${index}scriptarea`"
          >
            <template v-slot:label>
              Текст быстрой фразы
            </template>
            <template v-slot:append>
              <EmojiPicker v-if="hasRight('scripts.manage')" :scriptIndex="index" @pushEmoji="pushEmoji"/>
            </template>
          </v-textarea>
          <div class="field-add mb-2 mt-5">Вставить поле в текст</div>
          <div class="script-fields__keywords d-flex flex-column" v-if="hasRight('scripts.manage')">
            <div class="d-flex mb-5 flex-column" v-for="k in keywords" :key="k.name">
              <span class="mr-2 mb-2 keyword-name">
                {{ k.name }}:
                <v-menu
                    top
                    offset-y
                    open-on-hover
                    v-if="k.comment"
                >
                <template v-slot:activator="{ on, attrs }">
                  <strong v-bind="attrs" v-on="on"><v-icon size="18"
                                                           color="#289E80">mdi-information-outline</v-icon></strong>
                </template>
                <v-list-item class="user-color-status d-flex justify-space-between align-center pa-3">
                  {{ k.comment }}
                </v-list-item>
              </v-menu>
              </span>
              <div class="d-flex flex-wrap">
                <div class="script-fields__keyword mr-2 mb-2" v-for="i in k.values"
                     :key="`${i.value}`"
                     @click="addKeyword(index, i.value)">
                  + {{ i.text }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <v-dialog
          v-model="deleteScriptModal"
          max-width="600"
      >
        <v-card>
          <v-card-title class="text-h5">
            Удаление быстрой фразы
          </v-card-title>
          <v-card-text>Вы действительно хотите удалить быструю фразу <span
              class="font-weight-bold">"{{ selectedScript.name }}"</span>?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                text
                @click="deleteScriptModal = false"
            >
              Отмена
            </v-btn>
            <v-btn
                color="red"
                text
                @click="deleteSelectedScript(selectedScript)"
            >
              Удалить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div class="grey--text mt-13" v-else>Быстрых фраз не найдено</div>
  </div>
</template>

<script>
import {hasRight} from '@/utils/access/hasRight';
import EmojiPicker from "../EmojiPicker";
import {mapActions, mapState} from "vuex";

export default {
  name: "ScriptsFields",
  components: {EmojiPicker},
  props: {
    scripts: {
      type: Array,
      required: false,
    },
    scriptGroups: {
      type: Array,
      required: false,
    },
    currentGroupId: {
      type: Number,
      default: 0,
    },
    currentGroupName: {
      type: String,
    },
  },
  data: () => ({
    localShowBy: 10,
    page: 1,
    scrollPosition: null,
    deleteScriptModal: false,
    selectedScript: {},
    search: '',
    timerId: null,
    files: [],
    localLoading: false,
  }),
  computed: {
    ...mapState("scripts", ["groupScripts", "keywords"]),
    ...mapState("common", ["tableMeta",]),
    ...mapState("clients", ['clientCustomFields']),
    ...mapState("deals", ['dealCustomFields']),
    pagesLength() {
      if (!this.localShowBy)
        return Math.ceil(this.tableMeta.total / 10);
      return Math.ceil(this.tableMeta.total / this.localShowBy);
    },
    maxShowBy() {
      if (this.localShowBy + this.localShowBy * (this.page - 1) > this.tableMeta.total) {
        return this.tableMeta.total;
      }
      return this.localShowBy + this.localShowBy * (this.page - 1);
    },
    firstShowBy() {
      if (this.tableMeta.total > 0) {
        return 1 + this.localShowBy * (this.page - 1);
      }
      return 0;
    },
    localScripts() {
      let sortScriptsById = [...this.groupScripts];
      sortScriptsById.sort(function (a, b) {
        return b.id - a.id;
      });
      sortScriptsById.forEach(i => {
        i.script_group_id = this.currentGroupId;
        i.script_group = {
          name: this.currentGroupName,
        };
      });
      return sortScriptsById;
    }
  },
  methods: {
    ...mapActions({
      getScriptsByGroup: 'scripts/getScriptsByGroup',
      deleteScript: 'scripts/deleteScript',
      editScript: 'scripts/editScript',
      removeGroupScripts: 'scripts/removeGroupScripts',
      addFile: 'scripts/addFile',
      deleteFile: 'scripts/deleteFile',
      getClientCustomFieldsForScripts: 'clients/getClientCustomFieldsForScripts',
      getDealCustomFieldsForScripts: 'deals/getDealCustomFieldsForScripts',
    }),
    hasRight: hasRight,
    init() {
      this.localLoading = true;
      let args = {
        per_page: this.localShowBy,
        page: this.page,
      };
      if (this.search) {
        args['filter[name]'] = this.search;
      }

      this.getScriptsByGroup({
        id: this.currentGroupId,
        pagination: args
      }).finally(() => {
        this.localLoading = false;
      })
    },
    deleteScriptModalOpen(item) {
      this.selectedScript = item;
      this.deleteScriptModal = true;
    },
    addKeyword(index, keyword) {
      this.localScripts[index].text += keyword;
      this.editScriptData(this.localScripts[index]);
    },
    pushEmoji(index, e) {
      this.$refs[`${index}scriptarea`][0].focus();
      this.localScripts[index].text += e;
    },
    changeGroup(item) {
      this.editScript(item).then(() => {
        this.init();
      });
    },
    deleteSelectedScript(item) {
      this.deleteScript(item).then(() => {
        this.deleteScriptModal = false;
        this.init();
      });
    },
    removeFile(script, file) {
      this.deleteFile({id: script, fileId: file});
    },
    downloadFile(fileUrl, fileName) {
      let a = document.createElement("a");
      a.href = fileUrl;
      a.setAttribute("download", fileName);
      a.setAttribute("target", '_blank');
      a.click();
    },
    editScriptData(script) {
      if (this.files.length) {
        //Если с файлами
        let formData = new FormData();
        this.files.forEach((item) => {
          formData.append('attachment', item);
          this.addFile({id: script.id, formData: formData});
        });
        this.files = [];
      } else {
        this.editScript(script);
      }
    }
  },
  mounted() {
    if (this.localScripts.length === 0) this.init();
    this.getClientCustomFieldsForScripts();
    this.getDealCustomFieldsForScripts();
  },
  beforeDestroy() {
    this.removeGroupScripts();
  },
  watch: {
    search() {
      if (this.timerId)
        clearTimeout(this.timerId);

      this.timerId = setTimeout(() => {
        this.page = 1;
        this.init();
      }, 500);
    }
  }
}
</script>

<style lang="scss" scoped>
.script-fields {
  .evi-text-field.file-input {
    max-width: 34px !important;
  }

  &__item {
    background: #FFFFFF;
    box-shadow: 0 8px 14px rgba(0, 0, 0, 0.13);
    border-radius: 10px;
    padding: 46px 40px;
    margin-bottom: 15px;
    position: relative;
  }

  &__menu {
    position: absolute;
    cursor: pointer;
    right: 30px;
    top: 30px;
  }

  &__keywords {
    display: flex;
    //position: absolute;
    //left: -23px;
    //bottom: -30px;
  }

  &__keyword {
    border: 1px solid $evi-main-color;
    border-radius: 7px;
    padding: 2px 16px;
    cursor: pointer;
    font-size: 12px;
    color: $evi-headline-color;
  }
}

.script-fields-menu {
  .v-list-item__title {
    font-weight: 600;
    font-size: 14px;
    padding: 15px;
  }

  .v-list-item__icon {
    align-self: center;
  }
}

.es-table {
  &__header {
    /*padding: 0 23px;*/
    margin-top: 32px;

    span {
      font-weight: normal;
      font-size: 14px;
    }
  }

  &__filtration {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 1062px;

    .evi-text-field {
      max-width: 263px;
      margin-top: -7px;
    }

    .evi-table-search {
      margin-right: 57px;
    }
  }

  &__showby {
    span {
      color: #ABAFB3;
      margin-top: 10px;
    }
  }
}


.evi-pagination {
  position: relative;
}

.evi-button-green {
  position: absolute;
  top: -55px;
  right: 10px;
}

.select-count {
  max-width: 100px;
}

.show-scripts {
  display: flex;
  justify-content: flex-end;
}

.es-table__showby {
  width: 46%;
  max-width: 500px;
}

.es-table__filtration {
  max-width: 100% !important;
}

.scripts-search {
  width: 50%;
  max-width: 100% !important;
}

.attachments__headline {
  color: $evi-grey-color;
}

.field-add {
  font-size: 11px;
  color: #808386;
}

.keyword-name {
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  color: $evi-headline-color;
  text-transform: uppercase;
}

</style>
