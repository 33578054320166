<template>
    <div class="script-group-wrapper">
        <v-text-field
                class="evi-text-field"
                color="#44D370"
                v-model="scriptGroup.name"
                :disabled="!hasRight('scripts.manage')"
        >
            <template v-slot:label>
                Название группы фраз
                <span class="important">*</span>
            </template>
        </v-text-field>
        <div class="script-group__buttons d-flex align-center" v-if="hasRight('scripts.manage')">
            <v-btn class="evi-button-green mr-7" @click="$emit('reset')">
                Сброс
            </v-btn>
            <v-btn class="evi-button-green evi-button-green--fill" :disabled="!scriptGroup.name" @click="save">
                Сохранить
            </v-btn>
        </div>
    </div>
</template>

<script>
    import {hasRight} from '@/utils/access/hasRight';

    export default {
        name: "ScriptGroupFields",
        props: {
            selectedScriptGroup: {
                type: Object,
                required: true,
            }
        },
        data: () => ({
            scriptGroup: {},
        }),
        methods: {
            reset () {
                this.scriptGroup = this.selectedScriptGroup;
            },
            hasRight: hasRight,
            save () {
                let payload = {
                    name: this.scriptGroup.name,
                    id: this.scriptGroup.id,
                };
                this.$emit('saveScriptGroup', payload);
            },
        },
        watch: {
            selectedScriptGroup(val) {
                if (val)
                    this.reset();
            }
        },
        mounted() {
            this.reset();
        }
    }
</script>

<style lang="scss" scoped>
.script-group-wrapper {
    max-width: 350px;
    margin-top: 50px;
}
.script-group__buttons {
    margin-top: 50px;
}
</style>
